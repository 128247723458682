@import "~select2/dist/css/select2.min.css";

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    &:hover {
        background-color: #76C149;
        color: white;
    }
    background-color: #76C149;
    color: white;
}

.select2-container--default .select2-selection--single {
    height: 50px;
    padding-top: 12px;
}

.select2-container--default .select2-selection--multiple {
    height: 50px;
    padding-top: 6px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #76C149;
    border-color: #76C149;
}
