@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css";

.form-group {
    input.form-control {
        height: 50px;
    }
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #76C149;
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #76C149;
}
